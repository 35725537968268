<template>
  <Header :disconectSocket="disconnectSocket"></Header>
  <div id="body" style="background: #090909" class="min-h-full">
    <router-view />

  </div>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/Header';
import { useI18n } from 'vue-i18n';
import { io } from 'socket.io-client';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { ref } from 'vue';
export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  setup() {
    const { t } = useI18n();
    const api_webScocket = process.env.VUE_APP_HOST_BACKEND_WEBSOCKET
    const socket = ref(null); // Use refs for safer initialization
    let connectSocket = () => { };
    connectSocket = () => {
      if (api_webScocket) {
        if (!socket.value) {
          socket.value = io(api_webScocket, {
            auth: {
              token: localStorage.getItem('access_token'),
            },
          });

          socket.value.on('listemRoomWelcome', (payload) => {
            showSuccessMessage(payload.message);
          });

          socket.value.on('succesfullPurchaseMessage', (payload) => {
            if (payload.pvalues) {
              localStorage.setItem('pvalues', payload.pvalues);
              showSuccess();
            }
          });

          socket.value.on('disconectUser', (payload) => {
            showSuccessMessage(payload);
          });

          socket.value.on('conectedUser', (payload) => {
            showSuccessMessage(payload);
          });
        }
      }
    }
    const disconnectSocket = () => {
      if (socket.value) {
        socket.value.disconnect();
        socket.value = null; // Clear the reference
      }
    };
    /* socket = io(api_webScocket,{
      auth:{
        token: localStorage.getItem('access_token')
      }
    })
    console.log(socket)
    socket.on('listemRoomWelcome', (payload)=>{
      console.log(payload)
      showSuccessMessage(payload)
    })
    socket.on('succesfullPurchaseMessage', (payload)=>{
      if(payload.pvalues){
        localStorage.setItem('pvalues',payload.pvalues)
        showSuccess()
      }
      
    })
    socket.on('disconectUser', (payload) => {
      console.log(payload)
      showSuccessMessage(payload)
    })
    socket.on('conectedUser', (payload) => {
      console.log(payload)
      showSuccessMessage(payload)
    })
    const disconectSocket = () => {
      socket.disconnect();
    }*/
    const showSuccess = () => {
      toast.success(`${t('messageToasSuccesfullPurchase')}`, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        //theme: 'colored',
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress:
          undefined,
      });
    }
    const showSuccessMessage = (message) => {
      toast.info(`${t(`${message}`)}`, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        //theme: 'colored',
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress:
          undefined,
      });
    }
    connectSocket()
    return { showSuccess, disconnectSocket, showSuccessMessage };
  }
};
</script>

<style>
@font-face {
  font-family: "Montserrat Regular";
  src: url("@/assets/fonts/Montserrat-Regular.ttf");
}

#body {
  margin-top: 15px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  margin-top: -16px;
  font-family: "Montserrat Regular";
}
</style>
