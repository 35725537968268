<template>
  <div class="justify-content-center flex flex-wrap" >
    <CardItem class="md:w-4" :image="scaleImagen" title=""
      description="Special portals for marketing Pack">
    </CardItem>
    <!--  <div class="w-full">
      <select :on-change="updateImage()" v-model="selectedOption" style="width: 400px;"
        class="form-select form-select-lg container flex justify-content-center mt-5 mb-3">
        <option value="">Choose one Core Fairy Queen</option>
        <option value="1">Core of the Yellow Fairy Queen</option>
        <option value="2">Core of the Rose Fairy Queen</option>
        <option value="3">Core of the Purple Fairy Queen</option>
        <option value="4">Core of the Blue Fairy Queen</option>
        <option value="5">Core of the Green Fairy Queen</option>
      </select>
    </div>-->
    <div class="w-full" v-if="scaleImagenMain">
      <img class="container flex justify-content-center robot py-5" :src="scaleImagenMain" />
    </div>
    <div class="w-full">
      <ButtonCustomGF v-if="(!scaleVal || (scaleVal && !claimed)) && verifiedAccount"  id="buttonClaim9Third" class="container flex justify-content-center mt-5 mb-3"
      :class="scaleVal || (scaleVal && !claimed) ? '' : 'disabled '"  @click="claimScale7()" :msg="$t('claimAward')"> </ButtonCustomGF>
      <ButtonCustomGF v-if="scaleVal && claimed && verifiedAccount" id="buttonClaim9Third" class="container flex justify-content-center mt-5 mb-3 disabled" :msg="$t('claimed')"> </ButtonCustomGF>
    </div>
  </div>
  <modalMessage class="" v-if="showModal" title="Message Info" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
  <Spinner v-if="validRequestDisableSacale7"></Spinner>
</template>

<script>
import { ref } from "vue";
import modalMessage from '@/components/modal/modalMessage'
import imageScale4 from '@/assets/images/items/secondScale/scale6Icon1.png'
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'
import CardItem from '@/components/cards/card-item/cardItem';
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import { useLoading } from 'vue-loading-overlay'
import service from '@/mixins/service.js'
export default {
  components: {
    CardItem,
    ButtonCustomGF,
    
    modalMessage
  },
  mixins: [service],
  name: 'scale7-main',
  props: {
    msg: String,
    image: String,
    
    idPlayer: Number,
    scaleVal: Boolean,
    claimed: Boolean,
    verifiedAccount: Boolean
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let selectedOption = ref('')
    let status = ref('')
    let showModal = ref()
    let scaleImagen = ref(imageScale4)
    let bgImageFoo = ref(bgImageFooter)
    
    let user = ref({});
    const updateImage = () => {
      if (selectedOption.value == '') {
        scaleImagen.value = null;
      } else {
        scaleImagen.value = require(`@/assets/images/items/secondScale/scale6Icon${selectedOption.value}.png`);
      }
    }
    let style = ref('null')
    style = `   background : linear-gradient(135deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    user.value = JSON.parse(localStorage.getItem('user'))
    const claimScale7 = async () => {
      const $loading = useLoading();
      const loader = $loading.show();
      try {
        const data = {
          path: '/auth/claimScale',
          data: { scale: 'scale6Second' },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp) {
          loader.hide();
          console.log("scala actualizada")
          modalContent.value = 'successSendItem';
          showModal.value = true
          urlRedirect.value = `scales`
          status.value = "success"
        }
      } catch (error) {
        loader.hide();
        modalContent.value = "errorOccurred";
        showModal.value = true
        status.value = 'failed'
      }
    }
    return {
      bgImageFoo,
      style,
      scaleImagen,
      claimScale7,
      
      modalContent,
      urlRedirect,
      status,
      showModal,
      selectedOption,
      updateImage
    };
  },
};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}
</style>