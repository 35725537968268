<template>
  <div class="justify-content-center flex flex-wrap">
    <CardItem class="md:w-4" :image="scaleImagen" title="Magic Alchemy Clay x1000"
      description="">
    </CardItem>
    <div class="w-full">
      <!--  <select :on-change="updateImage()" v-model="selectedOption" style="width: 400px;"
        class="form-select form-select-lg container flex justify-content-center mt-5 mb-3">
        <option value="">Choose one</option>
        <option value="1">Pink Winter Costume</option>
        <option value="2">Cosplay Sasha Necron</option>
        <option value="3">Midnight Clothes</option>
        <option value="4">Sunaookami Shiroko Cosplay</option>
        <option value="5">Alisa Mikhailovna Kujou Cosplay</option>
      </select>-->
    </div>
    <div class="w-full" v-if="scaleImagenMain">
      <img class="container flex justify-content-center robot py-5" :src="scaleImagenMain" />
    </div>
    <div class="flex justify-content-center w-full">
      <ButtonCustomGF v-if="(!scaleVal || (scaleVal && !claimed)) && verifiedAccount" id="buttonClaim4"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3"
        :class="(scaleVal || (scaleVal && !claimed)) ? '' : 'disabled '" @click="claimScale4()" :msg="$t('claimAward')">
      </ButtonCustomGF>
      <ButtonCustomGF v-if="scaleVal && claimed && verifiedAccount" id="buttonClaim4"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3 disabled" :msg="$t('claimed')"> </ButtonCustomGF>
    </div>
  </div>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
  <Spinner class="mt-8" v-if="validRequestDisableSacale4"></Spinner>
</template>

<script>
import { ref } from "vue";
import modalMessage from '@/components/modal/modalMessage'
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'
import CardItem from '@/components/cards/card-item/cardItem'
//import imageScale4 from '@/assets/images/items/quartScale/scale2Icon.png'
import imageScale4 from '@/assets/images/items/alchemyClay.png'

import { useLoading } from 'vue-loading-overlay'
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import service from '@/mixins/service.js'
export default {
  components: {
    CardItem,
    ButtonCustomGF,

    modalMessage
  },
  mixins: [service],
  name: 'scale4-main',
  props: {
    msg: String,
    scaleVal: Boolean,
    claimed: Boolean,
    verifiedAccount: Boolean
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let status = ref('')
    let selectedOption = ref('')
    let showModal = ref()
    let scaleImagen = ref(imageScale4)
    let bgImageFoo = ref(bgImageFooter)
    const updateImage = () => {
      if (selectedOption.value == '') {
        scaleImagen.value = null;
      } else {
        scaleImagen.value = require(`@/assets/images/items/thirdScale/scale2Icon${selectedOption.value}.png`);
      }
    }
    const claimScale4 = async () => {
      const $loading = useLoading();
      const loader = $loading.show();
      try {
        const data = {
          path: '/auth/claimScale',
          data: { scale: 'scale2Quart' },
          //data: { scale: 'scale5' },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp && resp.statusCode == 200) {
          loader.hide();
          modalContent.value = 'successSendItem';
          showModal.value = true
          urlRedirect.value = `scales`;
          status.value = "success"
        } else {
          loader.hide();
          modalContent.value = "errorOccurred";
          showModal.value = true
          status.value = 'failed'
        }
      } catch (error) {
        loader.hide();
        modalContent.value = "errorOccurred";
        showModal.value = true
        status.value = 'failed'
      }
    }
    return {
      bgImageFoo,
      scaleImagen,
      claimScale4,
      selectedOption,
      updateImage,
      modalContent,
      urlRedirect,
      status,
      showModal
    };
  },
};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}
</style>