<template>
  <Spinner v-if="loading" :isLoading="loading" />
  <div v-else style="max-width:1000px; margin-top:1em" class="monster-view container-fluid dark">
    <div class="row">
      <!-- Información del Monstruo (lado izquierdo) -->
      <div class="col-lg-6">
        <div class="dark" style="width: 400px;">
          <div class="monster-information-table">
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th colspan="2" v-html="sanitizeHtml(monster.monster_name)"></th>
                </tr>
              </thead>
              <tbody>
                <tr style="background:none">
                  <td colspan="2" class="text-center">
                    <GltfViewer :idMonster="monster.material_id" />
                  </td>
                </tr>
                <tr>
                  <td>LVL</td>
                  <td v-if="monster.level">{{ monster.level }}</td>
                  <td v-else>0</td>
                </tr>
                <tr>
                  <td>HP</td>
                  <td v-if="monster.health_points">{{ monster.health_points }}</td>
                  <td v-else>0</td>
                </tr>
                <tr>
                  <td>MP</td>
                  <td v-if="monster.mana_points">{{ monster.mana_points }}</td>
                  <td v-else>0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Possible Drops (lado derecho) -->
      <div class="col-lg-6">
        <div v-if="possibleDrops.length > 0" class="drops-table dark">
          <h5 class="text-center">Drops</h5>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr v-for="drop in possibleDrops" :key="drop.item_id">
                <td :class="`${checkExclusivity(drop.item_id) ? 'exclusive' : ''}`">
                  <a :href="getItemUrl(drop.item_id)">
                    <img :src="`https://gfmemories.com/images/itemicon/${drop.ui_id_1?.toUpperCase()}.png`"
                      alt="Crafting Icon" class="img-fluid" style="width:32px">
                  </a>
                </td>
                <td :class="`${checkExclusivity(drop.item_id) ? 'exclusive' : ''} quality-${drop.item_quality}`">
                  <a :href="getItemUrl(drop.item_id)">{{ drop.item_name?.[lang] }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Lista de Ubicaciones del Monstruo -->
    <div v-if="monster.found_in_maps && monster.found_in_maps.length > 0" class="content-in mt-4">
      <h5 class="text-center text-white mb-3">{{ Zone }}</h5>
      <div class="row container bg-dark p-3 rounded">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th class="text-center col-4">NPC</th>
              <th class="text-center col-8">Map</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="location in monster.found_in_maps" :key="location.scene">
              <td class="text-center" v-html="sanitizeHtml(monster.monster_name)"></td>
              <td class="text-center">{{ location.scene_names }} (X: {{ location.x_coord }}, Y: {{
                location.y_coord }})</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Spinner from "@/components/spinner/Spinner.vue";
import service from '@/mixins/service.js';
import monsterPlaceholder from '@/assets/images/monster_placeholder.png'
import GltfViewer from '@/components/3d/GltfViewer.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'monster-data-main',
  components: {
    Spinner,
    GltfViewer
  },
  mixins: [service],
  props: {
    id_monster: Number,
  },
  methods: {
    checkExclusivity(id) {
      return (id >= 5200 && id <= 7000 || id >= 54336 && id <= 60000);
    },
    getItemUrl(id) {
      return `/database/item/${id}`;
    },
    sanitizeHtml(text) {
      // Reemplazar las etiquetas <br> escapadas por saltos de línea reales
      const sanitizedText = text.replace('</br>', '').replace(/&lt;br&gt;|&lt;\/br&gt;/g, '<br>');

      // Luego escapamos cualquier otro contenido HTML para evitar problemas de inyección
      const element = document.createElement('div');
      element.innerText = sanitizedText;

      // Retornamos el contenido escapado con los <br> interpretados correctamente
      return element.innerHTML.replace(/&lt;br&gt;/g, '<br>');
    },
  },
  setup(props) {
    let lang = ref('')
    let monster = ref({})
    let loading = ref(true);
    let possibleDrops = ref([]);
    const router = useRouter();

    lang.value = localStorage.getItem('language');
    if (lang.value === 'br') {
      lang.value = 'pt';
    }

    const getMonsterById = async () => {
      loading.value = true;
      const data = {
        path: `/monsters/${props.id_monster}`,
        method: 'GET',
      };

      try {
        const monsterData = await service.methods.callService(data);
        monster.value = monsterData;

        // Llamada para obtener los posibles drops
        if (monsterData.possible_drops) {
          const dropIds = Object.keys(monsterData.possible_drops).map(id => parseInt(id));
          const dataDrops = {
            path: `/items/findByIds`,
            method: 'POST',
            data: { ids: dropIds }
          };
          const drops = await service.methods.callService(dataDrops);
          possibleDrops.value = drops
            .sort((a, b) => a.item_name[lang.value].localeCompare(b.item_name[lang.value]))
            .sort((a, b) => b.item_quality - a.item_quality);
        }

        // Manejando la traducción de nombres y otros campos
        if (monsterData.monster_name) {
          monster.value.monster_name = monsterData.monster_name[lang.value];
        }

        if (monsterData.found_in_maps && monsterData.found_in_maps.length > 0) {
          monster.value.found_in_maps = monsterData.found_in_maps.map(map => ({
            ...map,
            scene_names: map.scene_names[lang.value]
          }));
        }

        // Agregar los query params dinámicamente
        const itemNameForURL = monster.value.monster_name.toLowerCase().replace(/\s+/g, '-');  // Convertir el nombre del ítem a un string apto para URL
        router.replace({
          params: {
            id: props.id_monster,
            lang: lang.value,
            name: itemNameForURL.replace(/<[^>]+>/g, ""),
          }
        });

      } catch (error) {
        console.error("Error loading monster data:", error);
      } finally {
        loading.value = false;
      }
    };

    getMonsterById();

    return {
      lang,
      monster,
      loading,
      possibleDrops,
      monsterPlaceholder
    };
  }
};
</script>

<style scoped>
.monster-view .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.stat {
  margin: 0.5em 0;
}

.table th,
.table td {
  vertical-align: middle;
  background-color: #464646;
  color: #FFFFFF;
}

.dark {
  padding: 2em;
}

.monster-name {
  font-weight: bold;
  font-size: 1.5em;
}

a {
  text-decoration: none;
}

.monster-placeholder {
  background: none !important;
  opacity: 50%;
}

a:hover {
  text-decoration: underline;
}

.drops-table {
  padding: 2em;
}


.content-in h5 {
  color: white;
  text-align: center;
}
</style>
