<template>
  <div class="justify-content-center flex flex-wrap" >
    <CardItem class="md:w-4" :image="scaleImagen" title="Fortification Inscriptions Pack"
      description="Claim the fortification pack content: Master's fortified Inscription level 1 x10 , Master's fortified Inscription level 2 x10.">
    </CardItem>
    <div class="flex justify-content-center w-full">
      <ButtonCustomGF v-if="(!scaleVal || (scaleVal && !claimed)) && verifiedAccount" id="buttonClaim1" class="flex justify-content-center mt-5 mb-3 w-full md:w-3"
        :class="scaleVal || (scaleVal && !claimed) ? '' : 'disabled '" @click="claimScale1()" :msg="$t('claimAward')"> </ButtonCustomGF>
      <ButtonCustomGF v-if="scaleVal && claimed && verifiedAccount" id="buttonClaim1" class="flex justify-content-center mt-5 mb-3 w-full md:w-3 disabled" :msg="$t('claimed')"> </ButtonCustomGF>
    </div>
  </div>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
  
</template>

<script>
import { ref } from "vue";
import modalMessage from '@/components/modal/modalMessage'
import CardItem from '@/components/cards/card-item/cardItem'
import imageScale from '@/assets/images/items/firstScale/scale1Icon1.png'
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import { useLoading } from 'vue-loading-overlay'
import service from '@/mixins/service.js'
export default {
  components: {
    CardItem,
    ButtonCustomGF,
    
    modalMessage
  },
  mixins: [service],
  name: 'scale1-main',
  props: {
    msg: String,
    scaleVal: Boolean,
    claimed: Boolean,
    verifiedAccount: Boolean
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let status = ref('');
    let showModal = ref()
    let scaleImagen = ref(imageScale)
    const claimScale1 = async () => {
      const $loading = useLoading();
      const loader = $loading.show();
      try {
        const data = {
          path: '/auth/claimScale',
          data: { scale: 'scale1' },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp && resp.statusCode == 200) {
          loader.hide();
          modalContent.value = 'successSendItem';
          showModal.value = true
          urlRedirect.value = `scales`;
          status.value = "success"
        } else {
          loader.hide();
          modalContent.value = "errorOccurred";
          showModal.value = true
          status.value = 'failed'
        }
      } catch (error) {
        loader.hide();
        modalContent.value = "errorOccurred";
        showModal.value = true
        status.value = 'failed'
      }
    }
    return {
      scaleImagen,
      claimScale1,
      
      modalContent,
      urlRedirect,
      status,
      showModal,
    };
  },
};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}
</style>