<!-- TomesComponent.vue -->
<template>
    <div>
        <ItemList :items="talentItems" :itemsTooltips="talentTooltips" :totalItems="talentItems.length"
            :currentPage="currentPage" :loading="loading" :renderPaginationControls="false" @changePage="changePage"
            @changeItemsPerPage="changeItemsPerPage" />
    </div>
</template>

<script>
import ItemList from '@/components/database/itemdata/ItemList.vue';

export default {
    name: 'skillStonesComponent',
    components: {
        ItemList,
    },
    props: {
        guideData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            talentItems: [],
            talentTooltips: [],
            currentPage: 1,
            itemsPerPage: 10,
            loading: false,
        };
    },
    watch: {
        guideData: {
            handler() {
                this.processskillStones();
            },
            immediate: true,
        },
    },
    methods: {
        processskillStones() {
            const items = this.guideData.skillStones;
            this.talentItems = items
                .map((item) => ({
                    id: item.item_id,
                    name: item.item_name[this.$i18n.locale === 'br' ? 'pt' : this.$i18n.locale] || '',
                    level: item.lvl_requirement,
                    icon: item.ui_id_1,
                    quality: item.item_quality,
                }));

            this.talentTooltips = items
        },
        changePage(page) {
            this.currentPage = page;
        },
        changeItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
        },
    },
};
</script>