<template>
  <div class="dark">
    <Spinner class="mt-8" :isLoading="isLoading"></Spinner>
    <div class="table-container">
      <table v-if="!isLoading" class="table table-striped table-hover">
        <thead>
          <tr>
            <th>{{ $t('set') }}</th>
            <th>{{ $t('setName') }}</th>
            <th>{{ $t('level') }}</th>
            <th>{{ $t('effect3Piece') }}</th>
            <th>{{ $t('effect4Piece') }}</th>
            <th>{{ $t('effect5Piece') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(set, index) in sets" :key="index">
            <td>
              <div class="set-icons">
                <img v-for="item in set.items" :key="item.item_id"
                  :src="`https://gfmemories.com/images/itemicon/${item.ui_id_1?.toUpperCase()}.png`" alt=""
                  style="width: 20px; height: 20px;" />
                <br>
              </div>
            </td>
            <td :class="`quality-${set.item_quality}`">
              {{ set.setName }}
            </td>
            <td class="level-column">
              {{ set.levelRange }}
            </td>
            <td v-html="set.setEffects[0]"></td>
            <td v-html="set.setEffects[1]"></td>
            <td v-html="set.setEffects[2]"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetsComponent',
  props: {
    guideData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sets: [],
    };
  },
  watch: {
    guideData: {
      handler() {
        this.processSets();
      },
      immediate: true,
    },
  },
  methods: {
    processSets() {
      const items = this.guideData.sets;

      const setsMap = {};

      items.forEach((item) => {
        if (item.item_set && item.item_set.length > 0) {
          const setKey = item.item_set.sort((a, b) => a - b).join('-');

          if (!setsMap[setKey]) {
            setsMap[setKey] = {
              items: [],
              setName: item.set_name[this.$i18n.locale === 'br' ? 'pt' : this.$i18n.locale] || '',
              item_quality: item.item_quality,
              levelRange: '',
              setEffects: [],
              minLevel: 0,
            };
          }

          setsMap[setKey].items.push(item);
        }
      });

      const itemTypeOrder = [1, 4, 2, 3, 5];

      let processedSets = Object.values(setsMap).map((set) => {
        const sortedItems = set.items.sort((a, b) => {
          const aIndex = itemTypeOrder.indexOf(parseInt(a.item_type));
          const bIndex = itemTypeOrder.indexOf(parseInt(b.item_type));

          const aOrder = aIndex === -1 ? Number.MAX_SAFE_INTEGER : aIndex;
          const bOrder = bIndex === -1 ? Number.MAX_SAFE_INTEGER : bIndex;

          return aOrder - bOrder;
        });

        const levels = sortedItems.map((item) => item.lvl_requirement);
        const minLevel = Math.min(...levels);
        const maxLevel = Math.max(...levels);
        const levelRange =
          minLevel === maxLevel ? `${minLevel}` : `${minLevel}-${maxLevel}`;

        const setEffects = [];
        const sampleItem = sortedItems[0];
        if (sampleItem && sampleItem.set_enchants) {
          const enchantValues = Object.values(sampleItem.set_enchants);
          enchantValues.forEach((enchant) => {
            const enchantText = enchant[this.$i18n.locale === 'br' ? 'pt' : this.$i18n.locale] || '';
            const colonIndex = enchantText.indexOf(':');
            const setEffect = colonIndex !== -1 ? enchantText.slice(colonIndex + 1).trim() : enchantText;
            setEffects.push(setEffect);
          });
        }

        return {
          items: sortedItems,
          setName: set.setName,
          item_quality: set.item_quality,
          levelRange,
          setEffects,
          minLevel, 
        };
      });


      this.sets = processedSets.sort((a, b) => a.minLevel - b.minLevel);
    },
  },
};
</script>

<style scoped>
.table-container {
  max-height: 600px;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #464646;
  z-index: 2;
}

.table th,
.table td {
  vertical-align: middle;
  background-color: #464646;
  color: #FFFFFF;
}

.level-column {
  min-width: 100px;
}

.set-icons {
  min-width: 30px;
  max-width: 35px;
}

.table th,
.table td {
  vertical-align: middle;
  background-color: #464646;
  color: #FFFFFF;
}

.level-column {
  min-width: 100px;
}

.set-icons {
  min-width: 30px;
  max-width: 35px;
}

</style>