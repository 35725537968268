<template>
  <div class="flex-wrap">
    <div class="row">
      <!-- Swiper en una columna -->
      <div class="col-md-6">
        <swiper :slidesPerView="1" :navigation="true" :direction="'vertical'" :spaceBetween="150" :rewind="true"
          :centeredSlides="true" :grabCursor="true" :modules="modules" @slideChange="onSlideChange" class="mySwiper">
          <swiper-slide v-for="(champion, index) in champions" :key="index">
            <img :src="champion.icon" :alt="champion.name" />
          </swiper-slide>
        </swiper>

      </div>

      <!-- Video en otra columna -->
      <div class="col-md-6">
        <div class="video-container">
          <video :src="currentVideo" autoplay muted loop></video>
          <div class="overlay">
            <p class="class-title">{{ $t(`${chosenChampion.title}`) }}</p>
            <p>{{ $t(`${chosenChampion.key}_description`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import sabio from '@/assets/images/druid.webp';
import serker from '@/assets/images/serker.webp';
import mago from '@/assets/images/archimage.webp';
import nigro from '@/assets/images/shinigami.webp';
import renger from '@/assets/images/renger.webp';
import asesino from '@/assets/images/asesino.webp';
import paladin from '@/assets/images/templar.webp';
import clero from '@/assets/images/prophet.webp';
import omega from '@/assets/images/omega.png';
import dc from '@/assets/images/dcc.png';
import samurai from '@/assets/images/samu.png';
import relojero from '@/assets/images/relo.png';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      rowsPerSlide: window.innerWidth > 1000 ? 3 : window.innerWidth > 850 ? 2 : window.innerWidth > 600 ? 1 : 1,
      currentVideo: require(`@/assets/video/${this.champions[0].key}_preview.mp4`), // Inicializa el video que se va a mostrar
      chosenChampion: this.champions[0]
    }
  },
  setup() {
    const champions = [
      { name: 'Serker', icon: serker, key: "dk", title: "titleBerserker" },
      { name: 'Paladin', icon: paladin, key: "hk", title: "titlePaladin" },
      { name: 'Mage', icon: mago, key: "am", title: "titleMagoBrujo" },
      { name: 'Nigro', icon: nigro, key: "nm", title: "titleNigromante" },
      { name: 'Renger', icon: renger, key: "th", title: "titleRanger" },
      { name: 'Asesino', icon: asesino, key: "ao", title: "titleAsesino" },
      { name: 'Druid', icon: sabio, key: "sage", title: "titleSabio" },
      { name: 'Clero', icon: clero, key: "aa", title: "titleClerigo" },
      { name: 'Omega', icon: omega, key: "prime", title: "titleArtificiero" },
      { name: 'DC', icon: dc, key: "dc", title: "titleArtillero" },
      { name: 'Samurai', icon: samurai, key: "samu", title: "titleDuelista" },
      { name: 'Relojero', icon: relojero, key: "chrono", title: "titleRelojero" }
    ];

    return {
      modules: [Navigation],
      champions,
    };
  },
  methods: {
    onSlideChange(swiper) {
      const activeIndex = swiper.activeIndex;
      const activeChampionKey = this.champions[activeIndex].key;
      this.chosenChampion = this.champions[activeIndex];
      this.currentVideo = require(`@/assets/video/${activeChampionKey}_preview.mp4`);
    }
  },
};
</script>

<style scoped>
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.class-title {
  font-size: 15pt;
}

.swiper {
  height: 400px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  object-fit: cover;
  text-align: center;
  /* Recorta la imagen para ajustarse sin deformarse */
}

.swiper-slide img {
  height: 400px;
  padding: 1em;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
  /* Adjust the color and size */
  /* Altura fija */
  overflow: hidden;
  /* Recorta la imagen si es necesario */
}

.swiper .swiper-button-prev {
  left: -30px !important;
  transform: rotate(90deg) !important;
  right: auto;
}

.swiper-button-next {
  right: -30px !important;
  transform: rotate(180deg);
  left: auto;
}

.video-container {
  position: relative;
  width: 100%;
  height: 400px;
  /* Puedes ajustar la altura si es necesario */
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ajusta el video para que cubra todo el contenedor sin deformarse */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 45%, rgba(0, 0, 0, 1) 100%);
  color: white;
  text-align: left;
  font-size: 16px;
}

@media (max-width: 600px) {
  .swiper-slide img {
    height: 300px;
  }
}

.flex-wrap {
  margin: 1em 3em;
}

.overlay p {
  margin: 0;
}
</style>
