<template>
    <div>
        <!-- Modal Trigger (not visible, modal auto-opens if first visit) -->
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#firstVisitModal"
            ref="openModalButton" style="display: none">
            Open Modal
        </button>

        <div class="modal fade" id="firstVisitModal" tabindex="-1" aria-labelledby="firstVisitModalLabel"
            aria-hidden="true">
            <div class="modal-dialog exclusive">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <img :src="img" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img from '@/assets/images/verification_popup.png'

export default {
    mounted() {
        if (!localStorage.getItem('hasVisited')) {
            this.$refs.openModalButton.click();
            localStorage.setItem('hasVisited', 'true');
        }
    },
    setup() {
        return {
            img
        };
    },
};
</script>

<style scoped>
.modal-dialog {
    max-width: 75vw;
}

.modal-dialog img {
    width: 100%;
}
</style>