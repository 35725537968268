<template>
  <div class="whole container-fluid">
    <BannerTitle :msg="$route.params.item_type === '0' ? 'Database' : dbTitle"></BannerTitle>
    <div class="row mb-3">
      <!-- Barra de búsqueda -->
      <div class="col-sm-8 g-3">
        <div class="input-group">
          <input v-model="searchQuery" type="text" class="form-control" placeholder="Buscar por nombre..."
            @keyup.enter="handleSearch" />
          <button class="btn btn-outline-secondary" @click="handleSearch">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <!-- Botones Filtros y Limpiar -->
      <div class="col-sm-2 g-3">
        <FilterModal @apply-filters="applyFilters" @clear-filters="clearFilters"></FilterModal>
      </div>
      <div class="col-sm-2 g-3">
        <button @click="clearFilters" class="btn btn-secondary w-100">
          Limpiar
        </button>
      </div>
    </div>

    <!-- Resumen de Filtros Activos -->
    <div class="row mb-3" v-if="activeFilters.length > 0">
      <div class="col-12">
        <div class="filters-summary">
          <span v-for="(filter, index) in activeFilters" :key="index" class="badge bg-primary me-2">
            {{ filter.label }}
            <i class="fa fa-times ms-1" @click="removeFilter(filter)"></i>
          </span>
        </div>
      </div>
    </div>

    <!-- Lista de Items -->
    <ItemList :items="items" :itemsTooltips="items_tooltips" :loading="loading" :totalItems="totalItems" :currentPage="currentPage"
      @changePage="handlePageChange" @changeItemsPerPage="handleItemsPerPageChange" />
  </div>
</template>


<script>
import ItemList from "@/components/database/itemdata/ItemList.vue";
import { ref } from "vue";
import service from "@/mixins/service.js";
import { mapState } from 'vuex';
import BannerTitle from '@/components/banner-title/BannerTitle';
import FilterModal from "@/components/database/itemdata/FilterModal.vue";
import { qualityAndClassMap } from "@/assets/js/qualityAndClassMap";

export default {
  name: "ListItemsView",
  data() {
    return {
      items: [],
      items_tooltips: [],
      totalItems: 0,
      currentPage: 1,
      itemsPerPage: 10,
      loading: false,
      searchQuery: "",
      selectedQuality: '',
      selectedClass: '',
      maxLevel: 100,
      minLevel: 0,
      filterUnusedContent: true,
      activeFilters: [],
      qualityMap: {},
    };
  },
  props: {
    item_type: Number,
  },
  mixins: [service],
  components: {
    BannerTitle,
    ItemList,
    FilterModal
  },
  async mounted() {
    await this.loadItems();
    this.activeFilters.push({ type: 'filterUnusedContent', label: `${this.$t('filterUnusedContent')}` });
    this.qualityMap = qualityAndClassMap(this);
  },
  computed: {
    ...mapState(['dbTitle']),
  },
  watch: {
    "$route.params.item_type": "resetFiltersAndLoadItems",
    "$route.params.auction_type": "resetFiltersAndLoadItems",
    "$route.params.rune_type": "resetFiltersAndLoadItems",
  },
  methods: {
    async resetFiltersAndLoadItems() {
      this.clearFilters(); // Limpiar los filtros antes de recargar los ítems
      this.currentPage = 1;
      await this.loadItems();
    },

    async loadItems() {
      this.loading = true;
      try {
        const { item_type, auction_type, rune_type } = this.$route.params;
        const { items, total, items_tooltips } = await this.getItems(
          this.currentPage,
          this.itemsPerPage,
          item_type,
          auction_type,
          rune_type,
          this.searchQuery,
          this.selectedClass,
          this.selectedQuality,
          this.minLevel,
          this.maxLevel,
          this.filterUnusedContent
        );
        this.items = items;
        this.totalItems = total;
        this.items_tooltips = items_tooltips
      } catch (error) {
        console.error("Error loading the table...", error);
      } finally {
        this.loading = false;
      }
    },

    async getItems(page, limit, item_type, auction_type, rune_type, searchQuery, item_class, quality, min_lvl, max_lvl, filterUnusedContent) {
      let lang = ref("");
      lang.value = localStorage.getItem("language");
      if (lang.value === "br") {
        lang.value = "pt";
      }
      if (!item_type) return;
      let path = `/items/find-items?page=${page}&limit=${limit}&item_type=${item_type}`;
      if (auction_type) {
        path += `&auction_type=${auction_type}`;
      }
      if (rune_type) {
        path += `&rune_type=${rune_type}`;
      }
      if (searchQuery) {
        path += `&item_name=${encodeURIComponent(searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))}`;
      }
      if (item_class) {
        path += `&item_class=${item_class}`;
      }
      if (quality) {
        path += `&quality=${quality}`;
      }
      if (min_lvl !== null && min_lvl !== undefined) {
        path += `&min_lvl=${min_lvl}`;
      }
      if (max_lvl !== null && max_lvl !== undefined) {
        path += `&max_lvl=${max_lvl}`;
      }
      if (filterUnusedContent) {
        path += "&filterUnusedContent=true";
      }

      path += "&lang=" + lang.value;

      const data = {
        path,
        method: "GET",
      };

      try {
        const { items, total } = await service.methods.callService(data);
        return {
          items: items.map((item) => {
            return {
              id: item.item_id,
              icon: item.ui_id_1,
              quality: item.item_quality,
              name: item.item_name[lang.value],
              level: item.lvl_requirement,
            };
          }),
          total: total,
          items_tooltips:items
        };
      } catch (error) {
        console.log("An error occurred while loading the items");
        return { items: [], total: 0, items_tooltips: [] };
      }
    },

    async handlePageChange(newPage) {
      this.currentPage = newPage;
      await this.loadItems();
    },

    async handleItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.currentPage = 1;
      await this.loadItems();
    },

    async handleSearch() {
      this.currentPage = 1;
      await this.loadItems();
    },

    async applyFilters(filters) {
      this.selectedQuality = filters.quality;
      this.selectedClass = filters.class;
      this.minLevel = filters.minLevel;
      this.maxLevel = filters.maxLevel;
      this.filterUnusedContent = filters.filterUnusedContent;
      this.updateActiveFilters(); // Actualizar los filtros activos
      this.currentPage = 1; // Reiniciar la página al aplicar filtros
      await this.loadItems(); // Cargar los ítems con los filtros aplicados
    },

    async clearFilters() {
      this.searchQuery = "";
      this.selectedQuality = '';
      this.selectedClass = '';
      this.maxLevel = 100;
      this.minLevel = 0;
      this.filterUnusedContent = true;
      this.activeFilters = []; // Limpiar los filtros activos
      this.currentPage = 1;
      await this.loadItems(); // Recargar los ítems con filtros limpiados
    },

    updateActiveFilters() {
      this.activeFilters = [];
      if (this.selectedQuality) {
        this.activeFilters.push({ type: 'quality', label: `${this.$t('labelQuality')}: ${this.qualityMap.qualityMap[parseInt(this.selectedQuality)]}` });
      }
      if (this.selectedClass) {
        this.activeFilters.push({ type: 'class', label: `${this.$t('labelClass')}: ${this.qualityMap.classMap[parseInt(this.selectedClass)]}` });
      }
      if (this.minLevel > 0) {
        this.activeFilters.push({ type: 'minLevel', label: `Min Level: ${this.minLevel}` });
      }
      if (this.maxLevel < 100) {
        this.activeFilters.push({ type: 'maxLevel', label: `Max Level: ${this.maxLevel}` });
      }
      if (this.filterUnusedContent) {
        this.activeFilters.push({ type: 'filterUnusedContent', label: `${this.$t('filterUnusedContent')}` });
      }
    },

    removeFilter(filter) {
      switch (filter.type) {
        case 'quality':
          this.selectedQuality = '';
          break;
        case 'class':
          this.selectedClass = '';
          break;
        case 'minLevel':
          this.minLevel = 0;
          break;
        case 'maxLevel':
          this.maxLevel = 100;
          break;
        case 'filterUnusedContent':
          this.filterUnusedContent = !this.filterUnusedContent;
          break;
      }
      this.applyFilters({
        quality: this.selectedQuality,
        class: this.selectedClass,
        minLevel: this.minLevel,
        maxLevel: this.maxLevel,
        filterUnusedContent: this.filterUnusedContent
      });
    }
  },
};
</script>

<style scoped>
.whole {
  padding: 2% 10% 0 10%;
}
@media only screen and (max-width: 1200px) {
  .whole {
    padding: 2% 5% 0 5%;
  }
}
.filters-summary {
  padding: 0.2rem;
}

.filters-summary .badge {
  font-size: 1rem;
  cursor: pointer;
  background-color: #e9952e !important;
}

.filters-summary .badge .fa-times {
  margin-left: 0.5rem;
  font-size: 0.85rem;
  cursor: pointer;
}
</style>
