<template>

  <div class="col py-3">
    <BannerTitle msg="Item Data"></BannerTitle>
    <ItemData :item_id="$route.params.id_item"></ItemData>
  </div>

</template>

<script>
// @ is an alias to /src
import BannerTitle from '@/components/banner-title/BannerTitle'
import ItemData from '@/components/database/itemdata/ItemData.vue'
export default {
  name: 'ItemSingle',
  components: {
    BannerTitle,
    ItemData
  },
  setup() {
    return {
    }
  },
}
</script>
