<template>
  <form v-if="!validRequestDisable" onsubmit="return false" class="container w-full md:w-4 justify-content-center">
    <div class="container row g-0">
      <div class="message-error-send-form mb-2">{{ messageErrorSendForm }}</div>
      <div class="form-floating mb-3 w-full">
        <input type="text" autocomplete="off" class="form-control" v-on:keyup="validateUsername"
          v-model="serviceForm.account.mid"
          :class="(validUser == 'true') ? ' is-valid' : (validUser == 'false') ? ' is-invalid' : ' '"
          id="floatingInputNickName" placeholder="name@example.com">
        <label for="floatingInputNickName"> {{ $t('userName') }} </label>
        <div :class="(validUser == 'true') ? ' valid-feedback' : (validUser == 'false') ? ' invalid-feedback' : ' '">
          {{ validationsMessagesForm.account.mid.message }}
        </div>
      </div>
      <div class="form-floating mb-3 w-full">
        <input type="email" autocomplete="off" class="form-control" v-on:keyup="validateEmail" v-model="serviceForm.email"
          :class="(validEmail == 'true') ? ' is-valid' : (validEmail == 'false') ? ' is-invalid' : ' '"
          id="floatingInputEmail" placeholder="name@example.com">
        <label for="floatingInputEmail">{{ $t('emailAddress') }}</label>
        <div :class="(validEmail == 'true') ? ' valid-feedback' : (validEmail == 'false') ? ' invalid-feedback' : ' '">
          {{ validationsMessagesForm.email.message }}
        </div>
      </div>
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-6">
        <input type="password" autocomplete="off" class="form-control" v-on:keyup="validatePassword"
          v-model="serviceForm.account.password"
          :class="(validPassword == 'true') ? ' is-valid' : (validPassword == 'false') ? ' is-invalid' : ' '"
          id="floatingPassword" :placeholder="$t('password')">
        <label for="floatingPassword">{{ $t('password') }}</label>
        <div
          :class="(validPassword == 'true') ? ' valid-feedback' : (validPassword == 'false') ? ' invalid-feedback' : ' '">
          {{ validationsMessagesForm.account.password.message }}
        </div>
      </div>
      <div class="form-floating md:w-6 w-12 md:w-6">
        <input type="password" autocomplete="off" class="form-control" v-on:keyup="validatePwd"
          v-model="serviceForm.account.pwd"
          :class="(validPwd == 'true') ? ' is-valid' : (validPwd == 'false') ? ' is-invalid' : ' '" id="floatingPwd"
          :placeholder="$t('password')">
        <label for="floatingPwd">{{ $t('confirmPassword') }}</label>
        <div :class="(validPwd == 'true') ? ' valid-feedback' : (validPwd == 'false') ? ' invalid-feedback' : ' '">
          {{ validationsMessagesForm.account.pwd.message }}
        </div>
      </div>
      
    </div>
    <div>
      <div class="content_checkbox container justify-content-center mb-5 mt-2" style="display: flex; align-items: center;">
        <div class="checkbox-wrapper-12" >
        <div class="cbx">
          <input  v-model="acceptCheckBox" id="cbx-12"  type="checkbox"/>
          <label for="cbx-12"></label>
          <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
            <path d="M2 8.36364L6.23077 12L13 2"></path>
          </svg>
        </div>
        <!-- Gooey-->
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo-12">
              <!-- <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
              <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
              <feblend in="SourceGraphic" in2="goo-12"></feblend> -->
            </filter>
          </defs>
        </svg>
       
      </div>
       <span class="text_checkbox ml-2 text-white">{{$t('textAcceptTerms1')}} <a :href="'/terms'" target="blank"> {{$t('terms')}}</a></span>
      </div>
    </div>
    <!-- Agregar el componente reCAPTCHA -->
    <div class="flex w-full justify-content-center">
      <VueHcaptcha size="normal" class="captcha-container" :sitekey="siteKey" :load-recaptcha-script="true"
        @verify="handleSuccess" @error="handleError"></VueHcaptcha>
    </div>
    <div class="flex w-full justify-content-center my-2">
      <ButtonCustomGF id="buttonRegister"
        :class="(validEmail == 'true' && validPassword == 'true' && validUser == 'true' && validPwd == 'true' && validCapchat == true && acceptCheckBox) ? ' ' : ' disabled'"
        @click="sendContactForm" :msg="$t('createAccount')"> </ButtonCustomGF>
    </div>
  </form>
  <Spinner class="mt-8" :isLoading="validRequestDisable"></Spinner>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
</template>

<script>
// @ is an alias to /src

import { ref } from "vue";
import modalMessage from '@/components/modal/modalMessage'
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import Spinner from '@/components/spinner/Spinner'
import service from '@/mixins/service.js'
export default {
  name: 'register-main',
  mixins: [service],
  components: {
    ButtonCustomGF,
    Spinner,
    VueHcaptcha,
    modalMessage
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let status = ref('')
    let showModal = ref()
    let validCapchat = ref(false)
    let siteKey = ref(`${process.env.VUE_APP_GOOGLE_CAPTCHA_KEY}`)
    let validRequestDisable = ref(false)
    let messageErrorSendForm = ref('')
    let validUser = ref('')
    let validEmail = ref('')
    let validPassword = ref('')
    let validPwd = ref('')
    let exist = ref()
    let acceptCheckBox = ref(false)
    let existEmail = ref()
    let validationsMessagesForm = ref({
      account: {
        mid: {
          required: 'User required',
          exist: 'The user already exist',
          notUpperCaseLetter: 'Can`t use uppercase or white space or double quotation mark or single quotation mark',
          message: '',
        },
        password: {
          required: 'password required',
          lengthMin: 'Is necesary 8 characters min',
          upperCase: 'Use necesary uppercase',
          message: '',
        },
        pwd: {
          required: 'password required',
          match: 'the password dont match',
          message: '',
        },
      },
      email: {
        required: 'email is required',
        valid: 'email no valid',
        validDomain: 'domain mail valid (just accept gmail.com - outlook.com - hotmail.com - yahoo.com)',
        validPlusSymbol: 'the mail dont content symbol "+"',
        exist: 'The email already exist',
        message: '',
      }
    })
    let serviceForm = ref({
      account: {
        mid: '',
        password: '',
        pwd: '',
        pvalues: 0,
        status: 0
      },
      email: '',
    })

    const createValidEmailForm = async () => {
      try {
        const data = {
          path: '/auth/validateEmailExist',
          data: { email: serviceForm.value.email },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp) {
          existEmail.value = await resp.result
        } else {
          alert("Failded on validate form.")
        }
      } catch (error) {
        console.log(error)
      }


    }
    const handleSuccess = () => {
      validCapchat.value = true
    }
    const handleError = () => {
      validCapchat.value = false
    }
    const createValidForm = async () => {

      try {
        const data = {
          path: '/users/validateNameExist',
          data: { account_name: serviceForm.value.account.mid },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp) {
          exist.value = await resp.result
        } else {
          alert("Failded on validate form.")
        }
      } catch (error) {
        console.log(error)
      }


    }
    const createContactForm = async () => {
      validRequestDisable.value = true
      try {
        const data = {
          path: '/users',
          data: serviceForm.value,
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp.idnum) {
          validRequestDisable.value = false
          localStorage.setItem('userCreate', resp.mid)
          modalContent.value = "accountCreated"
          showModal.value = true
          urlRedirect.value = `login`
          status.value = "success"
          //window.location.href = 'https://www.gfmemories.com/login'
        } else {
          validRequestDisable.value = false
          modalContent.value = "errorOccurred"
          showModal.value = true
          status.value = 'failed'
        }
      } catch (error) {
        validRequestDisable.value = false
        modalContent.value = "errorOccurred"
        showModal.value = true
        status.value = 'failed'
      }
    }
    const validateUsername = async () => {
      if (serviceForm.value.account.mid.length) {
        let regexUser = new RegExp(`^[a-z0-9\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+$`)//eslint-disable-line
        if (regexUser.test(serviceForm.value.account.mid)) {
          await createValidForm()
          if (exist.value == 0) {
            validUser.value = 'true'
            validationsMessagesForm.value.account.mid.message = ''
          } else {
            validUser.value = 'false'
            validationsMessagesForm.value.account.mid.message = validationsMessagesForm.value.account.mid.exist
          }

        } else {
          validUser.value = 'false'
          validationsMessagesForm.value.account.mid.message = validationsMessagesForm.value.account.mid.notUpperCaseLetter
        }
      } else {
        validUser.value = 'false'
        validationsMessagesForm.value.account.mid.message = validationsMessagesForm.value.account.mid.required
      }
    }
    const validatePassword = () => {
      if (serviceForm.value.account.password.length) {
        if (serviceForm.value.account.password.length >= 8) {
          let regexPass = new RegExp("(?=.*?[A-Z])");
          if (regexPass.test(serviceForm.value.account.password)) {
            validPassword.value = 'true'
            validationsMessagesForm.value.account.password.message = ''
          } else {
            validPassword.value = 'false'
            validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.upperCase
          }

        } else {
          validPassword.value = 'false'
          validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.lengthMin
        }
      } else {
        validPassword.value = 'false'
        validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.mid.required
      }
    }
    const validatePwd = () => {
      if (serviceForm.value.account.pwd.length) {
        if (serviceForm.value.account.pwd == serviceForm.value.account.password) {
          validPwd.value = 'true'
          validationsMessagesForm.value.account.pwd.message = ''
        } else {
          validPwd.value = 'false'
          validationsMessagesForm.value.account.pwd.message = validationsMessagesForm.value.account.pwd.match
        }
      } else {
        validPwd.value = 'false'
        validationsMessagesForm.value.account.pwd.message = validationsMessagesForm.value.account.pwd.required
      }
    }
    const validateEmail = async () => {
      if (serviceForm.value.email.length) {
        let regexEmail = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$") //eslint-disable-line
        if (regexEmail.test(serviceForm.value.email)) {
          let arrayEmail = serviceForm.value.email.split('@')
          if ((arrayEmail[1] == 'gmail.com' || arrayEmail[1] == 'gmail.es') || (arrayEmail[1] == 'outlook.com' || arrayEmail[1] == 'outlook.es')
            || (arrayEmail[1] == 'msn.com' || arrayEmail[1] == 'msn.es') || (arrayEmail[1] == 'yahoo.com' || arrayEmail[1] == 'yahoo.es')
            || (arrayEmail[1] == 'hotmail.com' || arrayEmail[1] == 'hotmail.es')
          ) {
            if (serviceForm.value.email.includes("+")) {
              validEmail.value = 'false'
              validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.validPlusSymbol
            } else {
              await createValidEmailForm()
              if (existEmail.value == 0) {
                validEmail.value = 'true'
                validationsMessagesForm.value.email.message = ''
              } else {
                validEmail.value = 'false'
                validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.exist
              }
            }
          } else {
            validEmail.value = 'false'
            validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.validDomain
          }

        } else {
          validEmail.value = 'false'
          validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.valid
        }

      } else {
        validEmail.value = 'false'
        validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.required
      }
    }
    const validateAllForm = () => {
      if (validEmail.value == 'true' && validPwd.value == 'true' && validPassword.value == 'true' && validUser.value == 'true') return true; else return false
    }
    const sendContactForm = () => {
      if (validateAllForm() && validCapchat.value && acceptCheckBox.value) {
        createContactForm()
      } else {
        messageErrorSendForm.value = "El formulario que desea enviar no es valido! verifique porfavor que cumpla con los requisitos establecidos"
      }

    }
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
    return {
      serviceForm,
      sendContactForm,
      validationsMessagesForm,
      validateUsername,
      validateEmail,
      validUser,
      validEmail,
      validPassword,
      validPwd,
      validatePassword,
      validatePwd,
      messageErrorSendForm,
      validRequestDisable,
      siteKey,
      handleSuccess,
      handleError,
      validCapchat,
      modalContent,
      showModal,
      urlRedirect,
      status,
      acceptCheckBox
    }
  },
}
</script>
<style scoped>
.content_checkbox {
  display: flex;

}
@media (min-width: 560px) {
  .text_checkbox {
    font-size: 14px;
  }
}

  .checkbox-wrapper-12 {
    vertical-align: middle;
    position: relative;
  }
  .checkbox-wrapper-12 > svg {
    position: absolute;
    top: -130%;
    left: -170%;
    width: 110px;
    pointer-events: none;
  }
  .checkbox-wrapper-12 * {
    box-sizing: border-box;
  }
  .checkbox-wrapper-12 input[type="checkbox"] {
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    margin: 0;
  }
  .checkbox-wrapper-12 input[type="checkbox"]:focus {
    outline: 0;
  }
  .checkbox-wrapper-12 .cbx {
    width: 24px;
    height: 24px;
    top: calc(50vh - 12px);
    left: calc(50vw - 12px);
  }
  .checkbox-wrapper-12 .cbx input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #bfbfc0;
    border-radius: 50%;
  }
  .checkbox-wrapper-12 .cbx label {
    width: 24px;
    height: 24px;
    background: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-filter: url("#goo-12");
    filter: url("#goo-12");
    transform: trasnlate3d(0, 0, 0);
    pointer-events: none;
  }
  .checkbox-wrapper-12 .cbx svg {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
    pointer-events: none;
  }
  .checkbox-wrapper-12 .cbx svg path {
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 19;
    stroke-dashoffset: 19;
    transition: stroke-dashoffset 0.3s ease;
    transition-delay: 0.2s;
  }
  .checkbox-wrapper-12 .cbx input:checked + label {
    animation: splash-12 0.6s ease forwards;
  }
  .checkbox-wrapper-12 .cbx input:checked + label + svg path {
    stroke-dashoffset: 0;
  }
  @-moz-keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @-webkit-keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @-o-keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}

.cards-updates-double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 5%;
  gap: 10%;
}

.message-error-send-form {
  font-family: Montserrat Regular;
  font-size: 14px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

#buttonRegister.disabled {
  opacity: 0.4;
}

.captcha-container>#anchor {
  width: 100% !important;
}
</style>
  