<template>
  <BannerTitle msg="Tickets"></BannerTitle>
  <Tickets></Tickets>
</template>

<script>
import BannerTitle from '@/components/banner-title/BannerTitle'
import Tickets from '@/components/forms/tickets/Tickets'
export default {
  name: 'TicketsView',
  components: {
    BannerTitle,
    Tickets
  },
  setup() {
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }

    topPage()
    return {
    }
  },
}

</script>

<style scoped></style>