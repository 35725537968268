<template>
    <div class="col py-3">
        <BannerTitle msg="NPC Data"></BannerTitle>
        <MonsterData :id_monster="$route.params.id_monster"></MonsterData>
    </div>

</template>

<script>
// @ is an alias to /src
import BannerTitle from '@/components/banner-title/BannerTitle'
import MonsterData from '@/components/database/monsterdata/MonsterData.vue';

export default {
    name: 'ItemSingle',
    components: {
        BannerTitle,
        MonsterData
    },
    setup() {
        return {}
    },
}
</script>