<template>
  <ChangeName></ChangeName>
</template>

<script>
// @ is an alias to /src

import ChangeName from '@/components/forms/changeNamePlayer/ChangeName'
import { useRoute } from 'vue-router'
import service from '@/mixins/service.js'
import { ref, watch } from 'vue'

export default {
  name: 'ChangeNameView',
  mixins: [service],
  components: {
    ChangeName,
  },
  setup() {
    let title = ref('')
    let banner = ref('')
    let myService = ref({})
    const route = useRoute()
    watch(
      () => route.params.id,
      () => {
        getService(route.params.id)
      },
    )
    const getService = async (id) => {
      const data = {
        path: `/services/${id}`,
        method: 'GET',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }
      const resp = await service.methods.callService(data)
      if (resp.id) {
        title.value = resp.name
        banner.value = resp.imageBlog1
        myService.value = resp
      }
      return resp
    }
    if (route.params.id) {
      getService(route.params.id)
    }
    return {
      myService,
      title,
      banner,
    }
  },
}
</script>
