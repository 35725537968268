<template>
  <div class="card awards-title card-pay" :style="style" style="width: 18rem;">
    <img :src="image" class="image-card-coin" alt="..." />
    <div class="card-body">
      <span class="flex justify-content-center pb-2 bonusText1">
        {{ $t(`${service_name}`) }} 
      </span>
      <!-- <span class="card-text flex justify-content-center pb-2 bonusText">+ {{ $t('bonificationMp', [bonus]) }}</span>-->
      <ButtonCustomGF id="buttonPay" :msg="priceText"
        :class="`flex justify-content-center align-items-center ${!checkValid ? 'elementor-toggle' : ''}`"
        :style="style_button" @click="BuyAP()"></ButtonCustomGF>
    </div>
  </div>
  <div id="overlay" v-if="validRequestDisable">
    <Spinner class="mt-8 spinner" :isLoading="validRequestDisable"></Spinner>
  </div>
</template>
<script>
import { ref } from "vue";
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'
import ButtonCustomGF from '@/components/forms/store/button-custom/ButtonBuy'
import service from '@/mixins/service.js'
import Spinner from '@/components/spinner/Spinner'

export default {
  name: 'card-pay-services-main',
  components: {
    ButtonCustomGF,
    Spinner
  },
  props: {
    bonus: Number,
    image: String,
    plan: String,
    price: Number,
    service_name: String,
    checkValid: Boolean,
  },
  setup(props) {
    let priceText = ref('')
    let bgImageFoo = ref(bgImageFooter)
    let style = ref('null')
    let style_button = ref('')
    let serviceForm = ref({
      pvalues: 0, 
      price: ''
    })
    let validRequestDisable = ref(false)
    //style = `   background : linear-gradient(135deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    style = `   background : linear-gradient(to right, #212121, #141414);`
    style_button.value = `margin-left: 20%;width: 60%;`
    priceText.value = `${parseInt(props.price)}$`
    let BuyAP = async () => {
      validRequestDisable.value = true
      serviceForm.value.price = props.plan
      const data = {
        path: '/payments/servicesStore',
        data: serviceForm.value,
        method: 'POST',
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      }
      const resp = await service.methods.callService(data)
      if (resp.rel) {
       
        window.location.href = await resp.href
      }
      else {
        validRequestDisable.value = false
        // alert('Buy error tracking')
      }
    }
    return {
      bgImageFoo,
      priceText,
      style,
      serviceForm,
      BuyAP,
      style_button,
      validRequestDisable
    };
  },
};
</script>

<style>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Asegúrate de que esté por encima de otros elementos */
}

.spinner {
  border: 4px solid #f3f3f3;
  /* Puedes personalizar el color y el tamaño */
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.container-card-pay {
  width: 100%;
}

.card-pay {
  margin: 3%
}

.elementor-toggle {
  pointer-events: none;
  /* Permitir eventos de puntero por defecto */
}

.image-card-coin {
  width: 100%;
  height: 100%;
}

.awards-title {
  color: #FFFFFF;
}

.bonusText1 {
  font-size: 22px;
  font-weight: 500;
}

.bonusText {
  color: #F1C707;
}
</style>