<template>
    <div class="item-view-main">
        <div class="flex justify-content-center">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//import BannerTitle from '@/components/banner-title/BannerTitle'
export default {
    name: 'ItemView',
    components: {
        //  BannerTitle,
    },
    setup() {
        return {
        }
    },
}
</script>

<style scoped>
.item-view-main {
    margin-left: 300px;
    height: 100%;
    color: floralwhite;
}

@media (max-width: 1110px) {
    .item-view-main {
        margin-left: 0px;
    }
}
</style>