<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ItemSingle',
  components: {
  },
  setup() {
    return {
    }
  },
}
</script>