export default {
  sections: [
    {
      id: 'all',
      titleKey: 'all',
      items: [
        { id: 0, routeKey: 'all', translationKey: 'all' },
        { id: 99, routeKey: 'exclusive', translationKey: 'exclusive' },
      ]
    },
    {
      id: 'guide',
      titleKey: 'guide',
      route: '/database/guide',
    },
    {
      id: 'weapons',
      titleKey: 'weapons',
      items: [
        { id: 7, routeKey: 'sword', translationKey: 'sword' },
        { id: 9, routeKey: 'mace', translationKey: 'mace' },
        { id: 11, routeKey: 'axe', translationKey: 'axe' },
        { id: 8, routeKey: '2hSword', translationKey: '2hSword' },
        { id: 12, routeKey: '2hAxe', translationKey: '2hAxe' },
        { id: 10, routeKey: '2hMace', translationKey: '2hMace' },
        { id: 13, routeKey: 'bow', translationKey: 'bow' },
        { id: 14, routeKey: 'gun', translationKey: 'gun' },
        { id: 15, routeKey: 'holyItem', translationKey: 'holyItem' },
        { id: 16, routeKey: 'staff', translationKey: 'staff' },
        { id: 17, routeKey: 'shield', translationKey: 'shield' },
        { id: 54, routeKey: 'machine', translationKey: 'machine' },
        { id: 55, routeKey: 'heavyMachine', translationKey: 'heavyMachine' },
        { id: 56, routeKey: 'cannon', translationKey: 'cannon' },
        { id: 19, routeKey: 'arrow', translationKey: 'arrow' },
        { id: 20, routeKey: 'bullet', translationKey: 'bullet' },
        { id: 57, routeKey: 'artillery', translationKey: 'artillery' },
        { id: 59, routeKey: 'crystalKatana', translationKey: 'crystalKatana' },
        { id: 60, routeKey: 'crystalKey', translationKey: 'crystalKey' },
      ]
    },
    {
      id: 'armor',
      titleKey: 'armor',
      items: [
        { id: 1, routeKey: 'head', translationKey: 'head' },
        { id: 2, routeKey: 'chest', translationKey: 'chest' },
        { id: 3, routeKey: 'pants', translationKey: 'pants' },
        { id: 4, routeKey: 'glove', translationKey: 'glove' },
        { id: 5, routeKey: 'feet', translationKey: 'feet' },
        { id: 6, routeKey: 'back', translationKey: 'back' },
        { id: 18, routeKey: 'trinket', translationKey: 'trinket' },
        { id: 21, routeKey: 'backpack', translationKey: 'backpack' },
      ]
    },
    {
      id: 'costume',
      titleKey: 'costume',
      items: [
        { id: 36, routeKey: '1hcostume', translationKey: '1hcostume' },
        { id: 37, routeKey: '2hcostume', translationKey: '2hcostume' },
        { id: 35, routeKey: 'auction_22', translationKey: 'auction_22', auctionType: 22 },
        { id: 35, routeKey: 'auction_23', translationKey: 'auction_23', auctionType: 23 },
        { id: 42, routeKey: 'suitcostume', translationKey: 'suitcostume' },
        { id: 22, routeKey: 'mounts', translationKey: 'mounts', auctionType: 38 },
      ]
    },
    {
      id: 'talent',
      titleKey: 'talent_items',
      items: [
        { id: 26, routeKey: 'talent', translationKey: 'talent' },
        { id: 33, routeKey: 'talent_blueprints', translationKey: 'talent_blueprints', auctionType: 32 },
        { id: 32, routeKey: 'talent_shards', translationKey: 'talent_shards' },
        { id: 22, routeKey: 'skills_upgrades', translationKey: 'skills_upgrades', auctionType: 32 },
        { id: 111, routeKey: 'ultimates', translationKey: 'ultimates' },
      ]
    },
    {
      id: 'spritesObj',
      titleKey: 'spritesObj',
      items: [
        { id: 22, routeKey: 'spriteInstrument', translationKey: 'spriteInstrument', auctionType: 37 },
        { id: 31, routeKey: 'spriteFurniture', translationKey: 'spriteFurniture', auctionType: 35 },
        { id: 30, routeKey: 'sprite_emblems', translationKey: 'sprite_emblems' },
      ]
    },
    {
      id: 'stone',
      titleKey: 'stone',
      items: [
        { id: 24, routeKey: 'prismatic', translationKey: 'prismatic', auctionType: 33, runeType: 'prismatic' },
        { id: 24, routeKey: 'runes', translationKey: 'runes', auctionType: 33, runeType: 'runestone' },
        { id: 24, routeKey: 'resonance', translationKey: 'resonance', auctionType: 33, runeType: 'resonance' },
      ]
    }
  ]
};
