<template>
    <!-- Esta barra sólo se mostrará cuando estén en la database. -->
    <div v-if="$route.path.includes('/database/')" :class="['sidebar', { 'collapsed': isSidebarCollapsed }]"
        class="col-3 px-1 position-fixed flex-shrink-0 p-3 shadow-lg bg-dark">
        <button @click="toggleSidebar" :class="['sidebar-toggle-btn', { 'moved': isSidebarCollapsed }]">
            {{ isSidebarCollapsed ? '>>' : '<<' }} </button>
                <div v-if="!isSidebarCollapsed"
                    class="d-flex flex-column align-items-start align-items-sm-start px-3 pt-2 text-white min-vh-100">
                    <a href="#//database/list-items/0"
                        class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
                        <!-- <i class="fa fa-database" aria-hidden="true"></i> -->
                        <img src="@/assets/images/logo-text-db.png" style="margin-right: 5%;" /><span
                            class="fs-5 fw-semibold" style="color: #e9952e !important;"> Memories Database</span>
                    </a>
                    <div class="w-100" style="overflow-x: hidden;
                overflow-y: visible;height: 65vh;">
                        <ul class="list-unstyled ps-0 nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start w-100"
                            id="menu">
                            <li class="nav-item" style="width: 95%;" v-for="section in sections" :key="section.id">
                                <div v-if="section.items && section.items.length">
                                    <button
                                        :class="[{ 'btn-custom': activeSection === section.id }, 'nav-link px-0 align-middle']"
                                        @click="toggleCollapse(section.id)"
                                        :aria-expanded="activeCollapse === section.id" data-bs-toggle="collapse"
                                        style="background-color: #141414; width: 100%; margin-bottom: 5%;">
                                        <div style="text-align: center; width: 100%;">
                                            <span class="d-sm-inline">
                                                <i class="fs-4 bi-speedometer2"></i> {{
                                                    $t(section.titleKey).toUpperCase() }}
                                            </span>
                                        </div>
                                    </button>
                                    <ul :class="['collapse', { show: activeCollapse === section.id }]"
                                        :id="`${section.id}-collapse`"
                                        class="collapse nav flex-column ms-1 btn-toggle-nav list-unstyled"
                                        data-bs-parent="#menu">
                                        <li class="w-100" v-for="item in section.items" :key="item.routeKey">
                                            <router-link :to="getUrl(item.id, item.auctionType, item.runeType)"
                                                class="nav-link px-0"
                                                @click="updateBannerTitle(item.translationKey, section.id)">
                                                {{ $t(item.translationKey) }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                                <!-- If the section doesn't have items, use the route property -->
                                <div v-else>
                                    <router-link :to="section.route"
                                        :class="[{ 'btn-custom': activeSection === section.id }, 'nav-link px-0 align-middle']"
                                        @click="updateBannerTitle(section.titleKey, section.id)"
                                        style="background-color: #141414; width: 100%; margin-bottom: 5%;">
                                        <div style="text-align: center; width: 100%;">
                                            <span class="ms-1 d-sm-inline">
                                                <i class="fs-4 bi-speedometer2"></i> {{
                                                    $t(section.titleKey).toUpperCase() }}
                                            </span>
                                        </div>
                                    </router-link>
                                </div>
                            </li>

                        </ul>
                    </div>

                    <hr>
                    <!-- <div class="dropdown pb-4">
                    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle list-unstyled " id="dropdownUser1" data-bs-toggle="dropdown">
                        <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
                        <span class="d-none d-sm-inline mx-1">loser</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                        <li><a class="dropdown-item" href="#">New project...</a></li>
                        <li><a class="dropdown-item" href="#">Settings</a></li>
                        <li><a class="dropdown-item" href="#">Profile</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Sign out</a></li>
                    </ul>
                </div> -->
                </div>
                <!--  <a href=#/#/database/list-items/0" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
            <span class="fs-5 fw-semibold"> <i class="fa fa-database" aria-hidden="true"></i> Memories Database</span>
        </a>
        <ul class="list-unstyled ps-0">
            <li v-for="section in sections" :key="section.id" class="mb-1">
                <button @click="toggleCollapse(section.id)" class="btn btn-toggle align-items-center rounded"
                    :aria-expanded="activeCollapse === section.id">
                    {{ $t(section.titleKey) }}
                </button>
                <div :class="['collapse', { show: activeCollapse === section.id }]" :id="`${section.id}-collapse`">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li v-for="item in section.items" :key="item.routeKey">
                            <router-link :to="getUrl(item.id, item.auctionType, item.runeType)"
                                class="link-dark rounded" @click="updateBannerTitle(item.translationKey)">
                                {{ $t(item.translationKey) }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>   -->
    </div>
</template>

<script>
import sections from '@/router/sidebar/sidebarRoutes';

export default {
    name: 'SideBar',
    data() {
        return {
            activeCollapse: null,
            sections: sections.sections,
            activeSection: null,
            isSidebarCollapsed: false // Estado para controlar si la sidebar está colapsada o no
        };
    },
    mounted() {
        if (window.innerWidth < 768) {
            this.isSidebarCollapsed = true;
        }
    },
    methods: {
        toggleSidebar() {
            this.isSidebarCollapsed = !this.isSidebarCollapsed;
        },
        getUrl(itemType, auctionType = null, rune = null) {
            return auctionType
                ? { name: 'listItemsView', params: { item_type: itemType, auction_type: auctionType, rune_type: rune } }
                : { name: 'listItemsView', params: { item_type: itemType } };
        },
        toggleCollapse(collapseId) {
            this.activeCollapse = this.activeCollapse === collapseId ? null : collapseId;
            this.activeSection = collapseId;
        },
        updateBannerTitle(translationKey, sectionId) {
            const title = this.$t(translationKey);
            this.$store.dispatch('updateDbTitle', title);
            document.title = `GF Memories - ${title}`;
            this.activeSection = sectionId;
            this.toggleSidebar();
        }
    }
};

</script>

<style scoped>
.sidebar {
    background-color: #202021;
    height: 91vh;
    top: 90px;
    color: aliceblue;
    transition: width 0.1s ease;
    /* Animación para colapso */
    width: 300px;
}

@media only screen and (max-width: 1200px) {
    .sidebar {
        background-color: #202021;
        height: 100vh;
        top: 0px;
        color: aliceblue;
        z-index: 99;
        padding-top: 20% !important;
    }
}

.collapsed {
    width: 0px;
    /* Ancho reducido cuando está colapsada */
}

.sidebar-toggle-btn {
    position: relative;
    top: 50%;
    left: 275px;
    transform: translateY(-50%);
    background-color: #202021;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    z-index: 100;
    transition: left 0.1s ease;
    /* Animación para colapso */
}

.moved {
    left: 0px;
}

.btn-custom {
    width: 100%;
    border: 1px solid #bcbc32;

}

.btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    background-color: transparent;
    border: 0;
}

.sidebar a span {
    color: #FFFFFF;
}

.sidebar ul li button {
    color: #FFFFFF;
}

.sidebar ul li a {
    color: #FFFFFF !important;
}

.fa {
    font-size: 30px !important;
    padding: 10px;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    color: white;
    filter: invert(100%);
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

.list-unstyled {
    padding-left: 2em;
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);


}
</style>
