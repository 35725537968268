<template>

    <div class="col py-3">
        <BannerTitle msg="Guide"></BannerTitle>
        <div>
            <div class="mb-3">
                <label for="baseClassSelect" class="form-label">{{ $t('classes') }}</label>
                <select style="width: 300px" id="baseClassSelect" class="form-select" v-model="selectedBaseClass">
                    <option v-for="(className, classId) in classOptions" :key="classId" :value="classId">
                        {{ $t(className) }}
                    </option>
                </select>
            </div>
            <!-- Tabs Navigation -->

            <div>
                <ul class="nav nav-tabs">
                    <li class="nav-item" v-for="tab in tabs" :key="tab.name">
                        <a class="nav-link" :class="{ active: activeTab === tab.name }" href="/"
                            @click.prevent="activeTab = tab.name">
                            {{ $t(tab.label) }}
                        </a>
                    </li>
                </ul>

                <!-- Tab Content -->
                <Spinner :isLoading="isLoading"></Spinner>
                <div v-if="!isLoading" class="tab-content mt-3">

                    <component :is="currentTabComponent" :guide-data="guideData"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BannerTitle from '@/components/banner-title/BannerTitle'
import SetsComponent from '@/components/database/guide/SetsComponent.vue';
import TomesComponent from '@/components/database/guide/TomesComponent.vue';
import TalentsComponent from '@/components/database/guide/TalentsComponent.vue';
import AncientTalentsComponent from '@/components/database/guide/AncientTalentsComponent.vue';
import SkillStonesComponent from '@/components/database/guide/SkillStonesComponent.vue';
import UltimatesComponent from '@/components/database/guide/UltimatesComponent.vue';

import Spinner from '@/components/spinner/Spinner.vue';


import service from '@/mixins/service.js';

export default {
    name: 'GuideView',
    components: {
        Spinner,
        BannerTitle,
        SetsComponent,
        TomesComponent,
        TalentsComponent,
        AncientTalentsComponent,
        SkillStonesComponent,
        UltimatesComponent
    },
    data() {
        return {
            activeTab: 'sets',
            selectedBaseClass: 3,
            isLoading: true,
            guideData: {
                sets: [],
                tomes: [],
                talents: [],
            },
            classOptions: {
                3: 'titleBerserker',
                4: 'titlePaladin',
                7: 'titleRanger',
                8: 'titleAsesino',
                11: 'titleClerigo',
                12: 'titleSabio',
                15: 'titleMagoBrujo',
                16: 'titleNigromante',
                27: 'titleArtificiero',
                28: 'titleArtillero',
                54: 'titleDuelista',
                55: 'titleRelojero',
            },
            tabs: [
                { name: 'sets', label: 'Sets', component: 'SetsComponent' },
                { name: 'tomos', label: 'tomes', component: 'TomesComponent' },
                { name: 'talents', label: 'talents', component: 'TalentsComponent' },
                { name: 'ancienttalents', label: 'ancientTalents', component: 'AncientTalentsComponent' },
                { name: 'skillStones', label: 'skillStones', component: 'SkillStonesComponent' },
                { name: 'ultimates', label: 'ultimates', component: 'UltimatesComponent' },
            ],
        };
    },
    computed: {
        currentTabComponent() {
            const tab = this.tabs.find(t => t.name === this.activeTab);
            return tab ? tab.component : null;
        },
    },
    methods: {
        async fetchGuideData() {
            this.isLoading = true;
            const data = {
                path: `/items/get-guide?base_class=${this.selectedBaseClass}`,
                method: 'GET',
            };

            try {
                const response = await service.methods.callService(data);
                this.guideData = response;
            } catch (error) {
                console.error('Error fetching guide data:', error);
            }
            finally {
                this.isLoading = false;
            }
        },

    },
    watch: {
        selectedBaseClass: {
            immediate: true,
            handler() {
                this.fetchGuideData();
            },
        },
    },
    created() {
        const classIds = Object.keys(this.classOptions);
        this.selectedBaseClass = classIds.length > 0 ? classIds[0] : null;
    },
}
</script>

<style scoped>
.nav-link {
    color: #fff;
}

.tab-content {
    padding: 2em;
}

.nav-tabs .nav-link.active {
    background-color: #e9952e;
    color: #fff;
}
</style>