<template>
  <banner-title :msg="$t('news')"></banner-title>
  <div class="flex justify-content-center pt-3 flex-wrap">
    <Spinner v-if="isLoading" :isLoading="isLoading" />
    <div v-for="currentNew in news" :key="currentNew.id">
      <card-news :id="currentNew.messageId" :title="'PATCH' + moment(currentNew.date)" :createDate="currentNew.date"
        :descriptionHtml="currentNew.content"></card-news>
    </div>
  </div>
</template>

<script>
import BannerTitle from '@/components/banner-title/BannerTitle';
import CardNews from '@/components/cards/news/card-news.vue';
import Spinner from '@/components/spinner/Spinner.vue';
import service from "@/mixins/service";
import moment from 'moment';
import { ref } from 'vue';
export default {
  name: 'NewsView',
  mixins: [service],
  components: {
    'banner-title': BannerTitle,
    'card-news': CardNews,
    'Spinner': Spinner
  },
  methods: {
    getTitle(currentText) {
      const lang = localStorage.getItem('language').charAt(0).toUpperCase() + localStorage.getItem('language').slice(1);
      const text = currentText[`descriptionHtml${lang}`];
      var regex = /<strong><center>(.*?)<\/center><\/strong>/;
      const resp = text.match(regex);
      return resp ? resp[1] : '';
    }
  },
  setup() {
    let news = ref([]);
    let isLoading = ref(false);
    const getNews = async () => {
      const lang = localStorage.getItem('language');
      isLoading.value = true;
      const data = {
        path: `/patchnotes/${lang === 'br' ? 'pt' : lang}`,
        method: 'GET',
      }
      const listNews = await service.methods.callService(data);
      news.value = listNews;
      isLoading.value = false;
    }
    getNews();
    return {
      news,
      moment,
      isLoading
    }
  },
}

</script>

<style scoped></style>