
export const qualityAndClassMap = (context) => {
    return{
    qualityMap: {
        1: context.$t('qualityGray'),
        0: context.$t('qualityWhite'),
        2: context.$t('qualityGreen'),
        3: context.$t('qualityBlue'),
        4: context.$t('qualityOrange'),
        5: context.$t('qualityYellow'),
        6: context.$t('qualityPurple'),
        7: context.$t('qualityRed'),
      },
      classMap: {
        0: context.$t('titleNovato'),
        1: context.$t('titleLuchador'),
        2: context.$t('titleGuerrero'),
        3: context.$t('titleBerserker'),
        4: context.$t('titlePaladin'),
        5: context.$t('titleCazador'),
        6: context.$t('titleArquero'),
        7: context.$t('titleRanger'),
        8: context.$t('titleAsesino'),
        9: context.$t('titleAcolito'),
        10: context.$t('titleSacerdote'),
        11: context.$t('titleClerigo'),
        12: context.$t('titleSabio'),
        13: context.$t('titleHechicero'),
        14: context.$t('titleMago'),
        15: context.$t('titleMagoBrujo'),
        16: context.$t('titleNigromante'),
        17: context.$t('titleDW'),
        18: context.$t('titleHk'),
        19: context.$t('titleTh'),
        20: context.$t('titleAo'),
        21: context.$t('titleProfeta'),
        22: context.$t('titleMistico'),
        23: context.$t('titleAM'),
        24: context.$t('titleDM'),
        25: context.$t('titleMecanico'),
        26: context.$t('titleIngeniero'),
        27: context.$t('titleArtificiero'),
        28: context.$t('titleArtillero'),
        29: context.$t('titlePrime'),
        30: context.$t('titleOptimus'),
        32: context.$t('titleDK'),
        33: context.$t('titleCaballeroSagrado'),
        34: context.$t('titleBallestero'),
        35: context.$t('titleNinja'),
        36: context.$t('titleSanto'),
        37: context.$t('titleChaman'),
        38: context.$t('titleAvatar'),
        39: context.$t('titleAmoAlmas'),
        40: context.$t('titleDestructor'),
        41: context.$t('titleCruzado'),
        42: context.$t('titleDepredador'),
        43: context.$t('titleShinobi'),
        44: context.$t('titleArcangel'),
        45: context.$t('titleDruida'),
        46: context.$t('titleBrujo'),
        47: context.$t('titleShinigami'),
        48: context.$t('titleMegatron'),
        49: context.$t('titleGalvatron'),
        50: context.$t('titleOmega'),
        51: context.$t('titleDomadorCelestial'),
        52: context.$t('titleViajero'),
        53: context.$t('titleNomada'),
        54: context.$t('titleDuelista'),
        55: context.$t('titleRelojero'),
        56: context.$t('titleSamurai'),
        57: context.$t('titleMaestroTiempo'),
        58: context.$t('titleDimensionalista'),
        59: context.$t('titleOraculo'),
        60: context.$t('titleEspectroMortal'),
        61: context.$t('titleAlteradorCosmico'),
      }
    }
}
