<template>
  <Banner></Banner>
  <!-- <img class="img-bg" :src="bgImagebod" />-->
  <div style="color: white;" class="flex justify-content-center pt-3 flex-wrap">
    <h1>{{ $t('lastMoficationsTextPatch') }}</h1>
  </div>

  <div class="flex justify-content-center pt-3 flex-wrap">
    <PatchSection>
    </PatchSection>

  </div>
  <div style="color: white;" class="flex justify-content-center pt-3 flex-wrap">
    <h1>{{ $t('classes') }}</h1>
  </div>
  <div>
    <ChampionsSection style=""></ChampionsSection>
  </div>
  <ModalPopup></ModalPopup>
  <!--<div class="flex justify-content-evenly sm:w-full flex-wrap p-5" style="background-color: #090909;">
    <CardUpdate :title="$t('title1Home')" class="sm:w-10 lg:w-5 m-5 lg:m-0"
      :description="$t('title1HomeDesc')">
    </CardUpdate>
    <CardUpdate :title="$t('title2Home')" class="sm:w-10 lg:w-5 m-3 lg:m-0"
      :description="$t('title2HomeDesc')">
    </CardUpdate>
  </div> -->
</template>

<script>
// @ is an alias to /src

import Banner from '@/components/banner/Banner';
//import CardUpdate from '@/components/cards/card-updates/cardUpdates';
import PatchSection from '@/components/patch-section/PatchSection';
import ChampionsSection from '@/components/champions-section/ChampionsSection';
import ModalPopup from '@/components/modal-popup/ModalPopup.vue';

export default {
  name: 'HomeView',
  components: {
    ChampionsSection,
    PatchSection,
    Banner,
    ModalPopup,
  },
  setup() {
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
  },
}
</script>
<style scoped>
.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}
</style>
