<template>
  <form v-if="!loading || showModal">
    <div class="head p-4">
      <span>{{ $t('accountInfo') }}</span>
    </div>
    <div class="flex justify-content-center flex-wrap">
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-8 containerSettings flex justify-content-around flex-wrap">
        <span class="w-full md:w-4">{{ $t('userName') }}</span>
        <div class="w-full md:w-6">
          {{ accountName }}
        </div>
      </div>
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-8 containerSettings flex justify-content-around flex-wrap">
        <span class="w-full md:w-4">{{ $t('emailAddress') }}</span>
        <div class="w-full md:w-6 flex">
          <div v-if="!emailEditable">
            {{ emailInfo }}
          </div>
          <div v-if="emailEditable" class="w-full md:w-10">
            <input id="newEmail" @input="validateForm" v-on:keyup="validateEmail" autocomplete="off"
              class="form-control"
              :class="(validEmail == 'true') ? ' is-valid' : (validEmail == 'false') ? ' is-invalid' : ' '"
              v-model="form.newEmail.value" :placeholder="$t('insertTextField')">
            <div
              :class="(validEmail == 'true') ? ' valid-feedback' : (validEmail == 'false') ? ' invalid-feedback' : ' '">
              {{ validationsMessagesForm.email.message }}
            </div>
          </div>
          <div>
            <button v-if="!emailEditable" @click="changeEmailStatus('edit')" class="ml-2 btn rounded-pill"
              style="color:white"><span class="fa fa-edit" /></button>
            <div v-if="emailEditable" class="flex">
              <button v-if="form.newEmail.value != emailInfo && validEmail == 'true'" @click="changeEmailStatus('save')"
                class="ml-2 btn rounded-pill" style="color:green"><span class="fa fa-check" /></button>
              <button @click="changeEmailStatus('cancel')" class="ml-2 btn rounded-pill" style="color:red"><span
                  class="fa fa-close" /></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-8 containerSettings flex justify-content-around flex-wrap">
        <span class="w-full md:w-4">{{ $t('verifiedAccount') }}</span>
        <div class="w-full md:w-6">
          {{ $t(`verified${verified}`) }}
          <button @click="sendVerification" v-if="!verified && !emailEditable" type="button"
            class="ml-2 btn rounded-pill" style="background : linear-gradient(to right, #212121, #141414);">
            <span class="button-custom-gf-2">{{ $t('emailVerifyAccount') }}</span>
          </button>
        </div>
      </div>
    </div>
  </form>
  <Spinner class="mt-8" :isLoading="loading"></Spinner>
  <modalMessage class="" v-if="showModal" :title="messageModal.title" :status="messageModal.status"
    :content="messageModal.content" @close="showModal = false"></modalMessage>
</template>

<script>
import { ref } from "vue";
import Spinner from '@/components/spinner/Spinner';
import modalMessage from '@/components/modal/modalMessage'
import moment from "moment";
import service from '@/mixins/service.js'

export default {
  name: 'ViewProfile',
  mixins: [service],
  components: {
    Spinner,
    modalMessage
  },
  setup() {
    let loading = ref(false);
    let showModal = ref(false);
    let verified = ref(false);
    let emailEditable = ref(false);
    let messageModal = ref({
      content: '',
      title: '',
      status: ''
    });
    const emailInfo = ref(localStorage.getItem('email'));
    const accountName = ref(localStorage.getItem('account_name'));
    let initialForm = {
      newEmail: {
        value: emailInfo.value,
        error: null
      }
    };
    let form = ref(initialForm);
    let validateForm = () => {
      const { newEmail } = initialForm;
      // Current password
      if (newEmail.value == '') {
        newEmail.error = 'Current password required.';
      } else {
        newEmail.error = null;
      }
    }

    const sendVerification = async () => {
      loading.value = true;
      const localDate = localStorage.getItem('emailVerificationSended') || '2024-09-21';
      var now = moment(new Date()); //todays date
      var end = moment(localDate); // another date
      var duration = moment.duration(now.diff(end));
      if (duration.asMinutes() < 5) {
        messageModal.value = {
          title: 'Error',
          status: 'error',
          content: 'verifiedEmailWait',
        };
        showModal.value = true;
        loading.value = false;
        return false
      } else {
        const bodyData = {};
        if (form.value.newEmail.value != emailInfo.value) {
          bodyData.email = form.value.newEmail.value;
        }
        const data = {
          path: '/auth/send-verify-code',
          method: 'POST',
          data: bodyData
        }
        const resp = await service.methods.callService(data);
        if (resp.statusCode == 200) {
          localStorage.setItem('email', form.value.newEmail.value);
          localStorage.setItem('vfa', false);
          localStorage.setItem('emailVerificationSended', new Date());
          verified.value = false;
          messageModal.value = {
            title: 'Success',
            status: 'success',
            content: 'verifiedEmailSended',
          };
          showModal.value = true;
        } else {
          messageModal.value = {
            title: 'error',
            status: 'error',
            content: 'verifiedEmailError',
          };
          showModal.value = true;
        } 
        loading.value = false;
      }
      return true
    }
    let validationsMessagesForm = ref({
      email: {
        required: 'email is required',
        valid: 'email no valid',
        validDomain: 'domain mail valid (just accept gmail.com - outlook.com - hotmail.com - yahoo.com)',
        validPlusSymbol: 'the mail dont content symbol "+"',
        exist: 'The email already exist',
        message: '',
      }
    })
    let validEmail = ref('')
    let existEmail = ref()

    const validateEmail = async () => {
      if (form.value.newEmail.value.length) {
        let regexEmail = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$") //eslint-disable-line
        if (regexEmail.test(form.value.newEmail.value)) {
          let arrayEmail = form.value.newEmail.value.split('@')
          if ((arrayEmail[1] == 'gmail.com' || arrayEmail[1] == 'gmail.es') || (arrayEmail[1] == 'outlook.com' || arrayEmail[1] == 'outlook.es')
            || (arrayEmail[1] == 'msn.com' || arrayEmail[1] == 'msn.es') || (arrayEmail[1] == 'yahoo.com' || arrayEmail[1] == 'yahoo.es')
            || (arrayEmail[1] == 'hotmail.com' || arrayEmail[1] == 'hotmail.es')
          ) {
            if (form.value.newEmail.value.includes("+")) {
              validEmail.value = 'false'
              validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.validPlusSymbol
            } else {
              await createValidEmailForm()
              if (existEmail.value == 0) {
                validEmail.value = 'true'
                validationsMessagesForm.value.email.message = ''
              } else {
                validEmail.value = 'false'
                validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.exist
              }
            }
          } else {
            validEmail.value = 'false'
            validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.validDomain
          }

        } else {
          validEmail.value = 'false'
          validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.valid
        }

      } else {
        validEmail.value = 'false'
        validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.required
      }
    }


    const createValidEmailForm = async () => {
      try {
        const data = {
          path: '/auth/validateEmailExist',
          data: { email: form.value.newEmail.value },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp.statusCode == 200) {
          existEmail.value = await resp.result
        } else {
          alert("Failded on validate form.")
        }
      } catch (error) {
        console.log(error)
      }
    }

    const changeEmailStatus = async (status) => {
      switch (status) {
        case 'edit':
          emailEditable.value = true;
          break;
        case 'save':
          // eslint-disable-next-line no-case-declarations
          const res = await sendVerification();
          if (res) {
            emailEditable.value = false;
            emailInfo.value = form.value.newEmail.value;
          }
          break;
        case 'cancel':
          emailEditable.value = false;
          initialForm.newEmail.value = emailInfo.value;
          break;
      }
    }

    const accountVerified = async () => {
      const verifiedJS = JSON.parse(localStorage.getItem('vfa') || "false");
      if (!verifiedJS) {
        const data = {
          path: '/auth/getVerifyParameter',
          method: 'GET',
        }
        const resp = await service.methods.callService(data)
        if (resp.statusCode == 200) {
          verified.value = resp.isVerify;
        }
      } else {
        verified.value = verifiedJS;
      }
    }

    accountVerified();
    return {
      loading,
      showModal,
      messageModal,
      emailInfo,
      accountName,
      verified,
      sendVerification,
      accountVerified,
      emailEditable,
      changeEmailStatus,
      initialForm,
      validateForm,
      validateEmail,
      validationsMessagesForm,
      validEmail,
      form
    }
  },
}

</script>
<style scoped>
.head {
  font-size: 25px;
  color: white;
}

.containerSettings {
  font-size: 20px;
  color: white;
}

.button-custom-gf-2 {
  font-size: 10px;
  color: white;
}
</style>