<template>
  <BannerTitle :msg="$t('profile')"></BannerTitle>
  <div class="flex justify-content-center w-full">
    <div class="w-10">
      <ul class="nav nav-tabs navigatorProfile">
        <li class="nav-item">
          <a class="nav-link" @click="updateActive(1)" :class="{ active: active == 1 }" aria-current="page">{{
            $t('profile') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="updateActive(2)" :class="{ active: active == 2 }" aria-current="page">{{
            $t('security') }}</a>
        </li>
      </ul>
      <ViewProfile v-if="active == 1"></ViewProfile>
      <ChangePassword v-if="active == 2"></ChangePassword>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BannerTitle from '@/components/banner-title/BannerTitle'
import ChangePassword from '@/components/forms/profile/changePassword.vue'
import ViewProfile from '@/components/forms/profile/viewProfile.vue'
export default {
  name: 'ProfileView',
  components: {
    BannerTitle,
    ChangePassword,
    ViewProfile
  },
  setup() {
    let active = ref(1);
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    };
    const updateActive = (val) => {
      active.value = val;
    };
    topPage()
    return {
      updateActive,
      active
    }
  },
}

</script>

<style scoped>
.navigatorProfile li {
  cursor: pointer;
}
</style>