<template>
  <BannerTitle :msg="$t('createAccountT')"></BannerTitle>
  <!-- <img class="img-bg" :src="bgImagebod" />-->
  <Register></Register>

</template>

<script>
// @ is an alias to /src

import Register from '@/components/forms/register/Register'
import BannerTitle from '@/components/banner-title/BannerTitle'
export default {
  name: 'RegisterView',
  components: {
    BannerTitle,
    Register,
  },
  setup() {
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }

    topPage()
    return {
    }
  },
}
</script>
<style scoped>
.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}

.cards-updates-double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 5%;
  gap: 10%;
}
</style>