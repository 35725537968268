<template>
  <Spinner class="mt-8" :isLoading="loading"></Spinner>
  <div v-if="!loading && status == 'success'" class="container pt-5 pb-5 pl-8 pr-8">
    <div class="flex justify-content-center row">
      <img src="@/assets/images/logoexed.png" class="col-md-12" style="width: 350px;height: 350px;" alt="">
      <div style="color: white;text-align: center;">
        <span>
          <h1>
            {{ $t('textAccountVerifiedTitle') }}
          </h1>
          <h2>
            {{ $t('textAccountVerifiedDescription') }}
          </h2>

        </span>
      </div>
      <div class="text-success" style="text-align: center;padding: 2%">
        <font-awesome-icon :icon="['fas', 'circle-check']" beat size="7x" />
        <!--<font-awesome-icon :icon="['fas', 'ban']" beat size="6x" /> -->
      </div>
    </div>
  </div>
  <div v-if="!loading && status == 'error'" class="container pt-5 pb-5 pl-8 pr-8">
    <div class="flex justify-content-center row">
      <img src="@/assets/images/logoexed.png" class="col-md-12" style="width: 350px;height: 350px;" alt="">
      <div style="color: white;text-align: center;">
        <span>
          <h1>
            {{ $t('textAccountVerifiedTitleError') }}
          </h1>
          <h2>
            {{ $t('textAccountVerifiedDescriptionError') }}
          </h2>

        </span>
      </div>
      <div class="text-success" style="text-align: center;padding: 2%">
        <!-- <font-awesome-icon :icon="['fas', 'circle-check']" beat size="7x" /> -->
        <font-awesome-icon style="color: #ed4337;" :icon="['fas', 'ban']" beat size="6x" />
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

import Spinner from '@/components/spinner/Spinner';
import service from '@/mixins/service';
import { ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'CodeVerifyView',
  mixins: [service],
  components: {
    Spinner
  },
  setup() {
    let loading = ref(true);
    let status = ref('');
    const route = useRoute().query;
    if (!route.code) {
      useRouter().push({path: '/'})
      return
    }
    let uri = window.location.href.split('?');
    let tmp = uri[1].split('code=');
    console.log('URL: ', tmp[1] );
    const finalRoute = tmp[1];
    useRouter().push({ query: {} });
    const data = {
      path: '/auth/verify-code',
      method: 'POST',
      data: {
        code: finalRoute
      }
    }
    const findVal = async () => {
      const result = await service.methods.callService(data);
      if (result.statusCode == 200) {
        status.value = 'success'
      } else {
        status.value = 'error';
      }
      loading.value = false;
    }
    findVal();
    return {
      loading,
      status
    }
  },
}
</script>
<style scoped></style>