<!-- TomesComponent.vue -->
<template>
    <div>
        <ItemList :items="tomeItems" :itemsTooltips="tomeTooltips" :totalItems="tomeItems.length"
            :currentPage="currentPage" :loading="loading" :renderPaginationControls="false" @changePage="changePage"
            @changeItemsPerPage="changeItemsPerPage" />
    </div>
</template>

<script>
import ItemList from '@/components/database/itemdata/ItemList.vue';

export default {
    name: 'TomesComponent',
    components: {
        ItemList,
    },
    props: {
        guideData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tomeItems: [],
            tomeTooltips: [],
            currentPage: 1,
            itemsPerPage: 10,
            loading: false,
        };
    },
    watch: {
        guideData: {
            handler() {
                this.processTomes();
            },
            immediate: true,
        },
    },
    methods: {
        processTomes() {
            const items = this.guideData.tomes;
            this.tomeItems = items.map((item) => ({
                id: item.item_id,
                name: item.item_name[this.$i18n.locale === 'br' ? 'pt' : this.$i18n.locale] || '',
                level: item.lvl_requirement,
                icon: item.ui_id_1,
                quality: item.item_quality,
            }));
            this.tomeTooltips = items;
        },
        changePage(page) {
            this.currentPage = page;
        },
        changeItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
        },
    },
};
</script>